





























































export default
  name: 'payment_form'
  data: ->
    loading: true
    valid: true
    error_message: null
    message: null
    heat: null
    emailRules: [
      (v) -> !!v || 'Email ввести должны вы'
      (v) -> /.+@.+\..+/.test(v) || 'Email таким не может быть'
    ]
    agreement: false
    form:
        race_id: @$store.state.race_id
        custom_amount: true
        amount: (@$store.state.amount / 0.94).toFixed(2)
        token: @$store.state.token
        comment: null
        form_fields:
            'personal_name_last': null
            'personal_name_first': null
            'personal_birthday': null
            'personal_gender': null
            'contacts_email': null
            'contacts_phone': null
            'other_house': null
  computed:
    houses: -> @$store.state.houses
    registration_open: -> @$store.state.registration_open
    houseNumbers: ->
      @houses.map((h) -> h.number)
  mounted: ->
    @check_event_stage()
  methods:
    check_event_stage: ->
      url = 'https://api.reg.place/v3/events/slug/dz-2021'
      @axios.get(url)
        .then (res) =>
          event_stage = res.data.data.attributes.stage
          @$store.dispatch('setStage', event_stage)
          @$set(@, 'loading', false)
        .catch (e) -> console.error(e)
    prepare_form: ->
      copy = JSON.parse(JSON.stringify(@form))
      form_fields = {}
      for key, value of copy.form_fields
          key = key.replace(/^(personal|contacts|other)_/, '$1.')
          form_fields[key] = value
      copy.form_fields = form_fields

      copy

    reset: -> @$refs.form.reset()
    submit: ->
      return unless @$refs.form.validate()

      form_data = @prepare_form()

      @axios.post('https://api.reg.place/v1/heats', form_data)
        .then (res) =>
          if 'success' == res.data.status
            @$set(@, 'heat', res.data.heat)
            window.location.href = res.data.heat.payment_url
          else
            @error_message = [res.data.message, JSON.stringify(res.data.errors)].join('<br /><br />')
            @$vuetify.goTo(0)
        .catch (e) =>
          console.log(e);


